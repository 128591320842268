import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { LocalizationService } from './localization.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Slider, Personae, Footprint, Sustainability, Hr } from '../models';
import { LoadingService } from "./loading.service";

@Injectable({
  providedIn: 'root'
})
export class HomepageService extends RestService {

  constructor( private loadingService: LoadingService, http: HttpClient, localization: LocalizationService ) {
    super(http, localization);
  }

  /**
   * gel home slider settings
   */
  getLeaderBoardSlider(): Observable<any> {
    return this.http.get<Slider>(HomepageService.apiHost + 'homepage/slider').pipe(
      map((res) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return HomepageService.handleError(err);
      })
    );
  }

  /**
   * gel personae blocs settings
   */
  getPersonaBlocs(): Observable<any> {
    return this.http.get<Personae>(HomepageService.apiHost + 'homepage/personae').pipe(
      map((res:Personae) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return HomepageService.handleError(err);
      })
    );
  }

  /**
   * gel footprint blocs settings
   */
  getFootprintBlocs(): Observable<any> {
    return this.http.get<Footprint>(HomepageService.apiHost + 'homepage/footprint').pipe(
      map((res:Footprint) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return HomepageService.handleError(err);
      })
    );
  }

  /**
   * gel sustainability blocs settings
   */
  getSustainabilityBlocs(): Observable<any> {
    return this.http.get<Sustainability>(HomepageService.apiHost + 'homepage/sustainability').pipe(
      map((res:Sustainability) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return HomepageService.handleError(err);
      })
    );
  }

  /**
   * gel projetcs blocs settings
   */
  getProjectsBlocs(): Observable<any> {
    return this.http.get<Personae>(HomepageService.apiHost + 'homepage/projects').pipe(
      map((res:Personae) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return HomepageService.handleError(err);
      })
    );
  }

  /**
   * gel projetcs blocs settings
   */
  getHRBlocs(): Observable<any> {
    return this.http.get<Hr>(HomepageService.apiHost + 'homepage/hr').pipe(
      map((res:Hr) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return HomepageService.handleError(err);
      })
    );
  }

  /**
   * get node by id
   * @param node_id
   */
  getNodeDetails(node_id: number): Observable<any> {
    return this.http.get<Personae>(HomepageService.apiHost + 'alias/' + node_id).pipe(
      map((res:Personae) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return HomepageService.handleError(err);
      })
    );
  }
}
