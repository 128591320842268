<div class="content generic-news-list mb-5">
  <h3> {{ content?.title }} </h3>
  <div *ngFor="let news of content?.news">
    <div class="news-bloc" (click)="goToDetailsNews(news)">
      <img src="{{ news.image.url }}" alt="" />
      <h4>{{ news.title }}</h4>
      <p> {{ news.summary }} </p>
      <hr />
    </div>
  </div>
</div>
