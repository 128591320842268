import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from '../components/homepage/homepage.component';
import { NodeComponent } from '../components/node/node.component';
import { NewsroomComponent } from "../components/newsroom/newsroom.component";
import { DetailsComponent } from "../components/newsroom/details/details.component";
import { GenericComponent } from "../components/generic/generic.component";
import { ReferencesComponent } from "../components/references/references.component";
import { ReferenceComponent } from '../components/references/reference/reference.component';
import { ContactComponent } from "../components/contact/contact.component";
import { FormsComponent } from "../components/forms/forms.component";
import { NewsletterTagsFormComponent } from "../components/newsletter-tags-form/newsletter-tags-form.component";
import { NewsletterUnsubscriptionFormComponent } from "../components/newsletter-unsubscription-form/newsletter-unsubscription-form.component";
import { NewsletterUpdateFormComponent } from "../components/newsletter-update-form/newsletter-update-form.component";
import { SearchComponent } from "../components/search/search.component";
import { SitemapComponent } from "../components/sitemap/sitemap.component";
import { LanguageGuard } from '../guards/language.guard';

const routes: Routes = [
  {
    path: ':lang',
    component: HomepageComponent,
    canActivate: [LanguageGuard]
  },
  {
    path: ':lang/node/:node_id',
    component: NodeComponent,
  },
  {
    path: ':lang/references',
    component: ReferencesComponent,
  },
  {
    path: ':lang/reference/:node_id',
    component: ReferenceComponent,
  },
  {
    path: ':lang/generic/:node_id',
    component: GenericComponent,
  },
  {
    path: ':lang/newsroom',
    component: NewsroomComponent,
  },
  {
    path: ':lang/newsroom/:filter/:token',
    component: NewsroomComponent,
  },
  {
    path: ':lang/newsroom/:news_id',
    component: DetailsComponent,
  },
  {
    path: ':lang/contact',
    component: ContactComponent,
  },
  {
    path: ':lang/form/:form_id',
    component: FormsComponent,
  },
  {
    path: ':lang/cat-subscribe',
    component: NewsletterTagsFormComponent,
  },
  {
    path: ':lang/unsubscribe',
    component: NewsletterUnsubscriptionFormComponent,
  },
  {
    path: ':lang/preferences',
    component: NewsletterUpdateFormComponent,
  },
  {
    path: ':lang/search/:needle',
    component: SearchComponent,
  },
  {
    path: ':lang/sitemap.xml',
    component: SitemapComponent,
  },
  {
    path: '**',
    redirectTo: '/en'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
