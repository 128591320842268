import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SearcheService, LocalizationService, LoadingService } from "../../services";
import { Search, SearchResult } from "../../models";
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  currentLang: string;
  needle: string;
  start: number = 0;
  length: number = 10;
  searches: Search;
  pages: number[] = [1];
  activePage: number = 1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searcheService: SearcheService,
    private loadingService: LoadingService,
    private localization: LocalizationService,
    private navigation: NavigationService
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.needle = params.needle;

      this.localization.lang.subscribe(lang => {
        this.currentLang = lang;
        this.route.queryParams.subscribe(params => {
          this.activePage = params.page ?? 1;
          this.start = this.length * (this.activePage - 1);
          this.search()
        });
      });
    });
  }

  /**
   * search a results
   */
  search(): void {
    this.searcheService.search(this.needle, this.start, this.length)
      .subscribe((results: Search) => {
        this.loadingService.stopLoading();
        this.searches = results;
        let nbPages = Math.ceil(this.searches.total / this.length);
        this.pages = Array.from({length: nbPages}, (v, k) => k + 1);
      });
  }

  /**
   * do search
   */
  doSearch(): false {
    let el = document.getElementById('top-hide-header');
    this.router.navigate([this.currentLang + '/search/' + this.needle])
      .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));

    return false;
  }

  /**
   * got te page
   */
  goToPage(page): false {
    let el = document.getElementById('top-hide-header');
    this.router.navigate([this.currentLang + '/search/' + this.needle,], { queryParams: {page: page}})
      .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));

    return false;
  }

  /**
   * go to link
   */
  goTo(result: SearchResult): false {
    let path = result.type;
    switch (result.type){
      case "leadership_paper": path = "form"; break;
      case "news": path = "newsroom"; break;
      case "page": path = "node"; break;
      case "article": path = "node"; break;
    }

    let el = document.getElementById('top-hide-header');
    this.router.navigate([this.currentLang + '/' + path + '/' + result.alias])
      .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));

    return false;
  }

  /** Newsletter form submission routing */
  back(): void {
    this.navigation.back()
  }
}
