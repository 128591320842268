import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {SlickCarouselComponent} from "ngx-slick-carousel";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  @Input() content: any;
  @HostListener("window:resize", ["$event"])

  slideConfig: object = {
    "slide": 'div',
    "infinite": true,
    "slidesToShow": 5,
    "slidesToScroll": 1,
    "speed": 500,
    "autoplay": true,
    "autoplaySpeed": 7000
  };

  constructor() { }

  ngOnInit(): void {
    this.getScreenSize();
  }

  /**
   * detect screen size and change slider config
   * @param event
   */
  getScreenSize(event?) {
    if (window.innerWidth < 900) {
      this.slideConfig["slidesToShow"] = 3;
    }

    if (window.innerWidth < 490) {
      this.slideConfig["slidesToShow"] = 1;
    }
  }
}
