import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { LocalizationService } from './localization.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from "./loading.service";


@Injectable({
  providedIn: 'root'
})
export class GenericService extends RestService {

  constructor( private loadingService: LoadingService, http: HttpClient, localization: LocalizationService ) {
    super(http, localization);
  }

  /**
   * get node by id
   * @param node_id
   */
  getGenericPage(node_id: number): Observable<any> {
    return this.http.get<any>(GenericService.apiHost + 'generic/' + node_id).pipe(
      map((res) => {
        this.localization.setAliases(res.aliases);
        return res.generic
      }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return GenericService.handleError(err);
      })
    );
  }
}
