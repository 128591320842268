import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalizationService, HeaderService, CommonService } from '../../services';
import { Link, Settings } from '../../models';
import { Router } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  settings: Settings;
  links: Array<Link> = [];
  megaMenuLinks: Array<Link> = [];
  megaMenuSubLinks: Array<Link> = [];
  megMenuLink: Link;
  actives_languages: Array<string> = ['en'];
  selected_lang: string;
  megaMenu: boolean = false;
  mobileMenu: boolean = false;
  searchForm: boolean = false;
  aliases: Array<any> = [''];
  @ViewChild('searchField') searchField: ElementRef;

  /**
   * HeaderComponent constructor
   * @param router
   * @param commonService
   * @param headerService
   * @param localization
   */
  constructor (
    private router: Router,
    private commonService: CommonService,
    private headerService: HeaderService,
    private localization: LocalizationService) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // get actives languages from backend
    this.localization.getActiveLanguages().subscribe(
      languages => this.actives_languages = languages);

    // watch selected lang, then translate main menu
    this.localization.lang.subscribe((lang) => {
      if(lang.length > 0) {
        this.getMainParentLinks(lang);
        this.getSettings();
      }
    });

    // menu stioky on windows scroll
    window.addEventListener('scroll', HeaderComponent.onWindowScroll, true);

    // close search form on reload page
    this.router.events.subscribe(() => this.closeSearchForm())

    // get aliases for curent url
    this.localization.aliases.subscribe((aliases: any) => {
      this.aliases = aliases;
    });

  }

  private static onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= 350) {
      document.getElementById('header').classList.add('sticky-menu');
    } else {
      document.getElementById('header').classList.remove('sticky-menu');
    }
  }

  /**
   * Get main menu parent links
   */
  private getMainParentLinks(lang: string) {
    this.selected_lang = lang;
    this.headerService.getMenuLinks('main')
      .subscribe((resp: Array<Link>) => {
        this.links = resp;
      });
  }

  /**
   * Get amc settings
   */
  private getSettings() {
    this.headerService.getSettings()
      .subscribe((resp: Settings) => {
        this.settings = resp;
      });
  }

  /**
   * show mega menu
   */
  showMegaMenu(link): void {
    this.headerService.getMenuLinks(link.id)
      .subscribe((resp: Array<Link>) => {
        if (resp.length > 0) {
          this.megaMenu = true;
          this.megMenuLink = link;
          this.megaMenuLinks = resp;
          this.megaMenuSubLinks = null;
        } else {
          this.megaMenu = false;
          this.megMenuLink = null;
          this.megaMenuLinks = null;
        }
      });
  }

  /**
   * hide mega menu
   */
  hideMegaMenu(): void {
    this.megaMenu = false;
  }


  /**
   * show sub mega menu
   */
  showSubMegaMenu(link) {
    if (!link.hasChildren) {
      return false;
    }

    this.headerService.getMenuLinks(link.id)
      .subscribe((resp: Array<Link>) => this.megaMenuSubLinks = resp);
  }

  /**
   * go to link
   * @param link
   */
  goTo(link: Link) {
    this.megaMenu = false;
    this.mobileMenu = false;
    this.commonService.goToLink(link);
    return false;
  }

  /**
   * change lang
   * @param lang
   */
  changeLang(lang: string) {
    let alias = this.aliases !== undefined ? this.aliases[lang] : false;
    if (alias !== false && alias.alias !== false) {
      this.commonService.redirectUrl(alias.type, alias.alias, lang);
    } else {
      this.commonService.redirectUrl("", "home", lang);
    }

    return false;
  }

  /**
   * go to link
   */
  showSearchForm(): false {
    this.megaMenu = false;
    this.mobileMenu = false;
    this.searchForm = true;
    setTimeout(() => {
      this.searchField.nativeElement.focus();
    }, 0);
    return false;
  }

  /**
   * go to link
   */
  closeSearchForm(): false {
    this.megaMenu = this.mobileMenu = this.searchForm = false;
    return false;
  }

  /**
   * go to link
   */
  search(needle): false {
    let el = document.getElementById('top-hide-header');
    this.router.navigate([this.selected_lang + '/search/' + needle])
      .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));

    return false;
  }
}
