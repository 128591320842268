import { Component, OnInit } from '@angular/core';
import { Cookie, Form } from "../../models";
import { ActivatedRoute } from "@angular/router";
import { CommonService, CookiesService, FormService, LoadingService, LocalizationService } from "../../services";
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  form: Form;
  field_recipient: string = "null";
  sended: boolean = false;
  recapcha: boolean = false;
  recapchaValid: boolean = false;
  recapchaSiteKey: string = '';
  cookieConfig: Cookie;
  cookieAccepted: boolean = false;

  /**
   * GenericComponent constructor
   * @param route
   * @param formService
   * @param commonService
   * @param cookieService
   * @param loadingService
   * @param localization
   */
  constructor(
    private route: ActivatedRoute,
    private formService: FormService,
    private commonService: CommonService,
    private cookieService: CookiesService,
    private loadingService: LoadingService,
    private localization: LocalizationService,
    private navigation: NavigationService
  ) {
  }

  /**
   * ng on init function
   */
  ngOnInit(): void {
    this.localization.lang.subscribe(() => this.getContactForm());

    this.cookieService.cookieAccepted.subscribe((cookie) => {
      this.cookieAccepted = cookie;
      this.getCookiesConfig();
    });
  }

  /**
   * Get contact form fields
   */
  getContactForm(): void {
    this.formService.getForm('contact')
      .subscribe((form: Form) => {
        this.loadingService.stopLoading();
        this.form = form;
        this.form.fields.sort(function(a:any, b:any) {
          return a.weight - b.weight;
        });
        this.commonService.setStaticAliases("contact");
      });
  }

  /**
   * get cookie config
   */
  getCookiesConfig(): void {
    this.cookieService.getCookiesConfig()
      .subscribe((cookie: Cookie) => {
        this.cookieConfig = cookie;
        this.recapcha = cookie.cookie_re_captcha;
        this.recapchaSiteKey = cookie.cookie_re_captcha_site_key;
      });
  }

  /**
   * resolved re-captcha
   *
   * @param captchaResponse
   */
  resolved(captchaResponse: string) {
    this.recapchaValid = true;
  }

  /**
   * cancel submit
   */
  submit(form) {
    this.loadingService.startLoading();
    let isValid: boolean = true;
    let data = form.value;
    Object.keys(data).forEach(key => {
      document.getElementById(key).classList.remove('error');
      let value = data[key];
      if (key == 'field_privacy_disclamer' && !value) {
        document.getElementById(key).parentElement.classList.add('error');
        isValid = false;
      } else if (document.getElementById(key).hasAttribute('required') && (!value || value == 'null')) {
        document.getElementById(key).classList.add('error');
        isValid = false;
      }
    });

    if (!this.cookieAccepted || (this.recapcha && !this.recapchaValid)) {
      isValid = false;
    }

    if (isValid) {
      data['recipients'] = this.form.config.recipients.join(',');
      this.formService.sendMail('contact', data)
        .subscribe((form: any) => {
          this.loadingService.stopLoading();
          document.getElementById('generic-section')
            .scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.sended = true;
        });
    } else {
      this.loadingService.stopLoading();
    }

  }

  /**
   * cancel submit
   */
  cancel(): void {
  }

  /**
   * hide cookie modal
   */
  accept(): boolean {
    this.cookieService.setCookie(this.cookieService.cookieName, "yes");
    this.cookieService.acceptCookie(true);
    return false;
  }

  /** Newsletter form submission routing */
  back(): void {
    this.navigation.back()
  }
}
