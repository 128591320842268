import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from "../../../services";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input() loading: boolean = false;

  constructor( private loadingService: LoadingService ) { }

  ngOnInit(): void {
    this.loadingService.loading.subscribe((loading) => {
      this.loading = loading
    })
  }
}
