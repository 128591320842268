import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LocalizationService } from "../../../services";
import { News } from "../../../models";

@Component({
  selector: 'app-related-news',
  templateUrl: './related-news.component.html',
  styleUrls: ['./related-news.component.css']
})
export class RelatedNewsComponent implements OnInit {

  @Input() content: any;
  protected static currentLang;

  constructor (
    private router: Router,
    private localization: LocalizationService
  ) {
    this.localization.lang.subscribe(lang => RelatedNewsComponent.currentLang = lang);
  }

  ngOnInit(): void {
  }

  /**
   * Go to news details
   * newsId:number
   */
  goToDetailsNews( news: News ): void {
    this.router.navigate([RelatedNewsComponent.currentLang + '/newsroom' + news.path]).then(() => {
      let el = document.getElementById('top-hide-header');
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }
}
