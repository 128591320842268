<div class="app-references">
  <section class="reference-breadcrumb">
    <div class="row">
      <div class="col-sm-12">
        <a href="/{{ currentLang }}/"><img src="../assets/img/icon/home_black.png" alt="home" /></a> |
        <span> {{ referencesConfig?.title }} </span>
      </div>
    </div>
  </section>

  <section class="generic-content" *ngIf="referencesConfig?.heading != null && referencesConfig?.heading.length > 0">
    <div class="row">
      <div class="col-sm-12 content" [innerHTML]="referencesConfig?.heading">
      </div>
    </div>
  </section>

  <section class="references">
    <div class="container-fluid">
      <div class="row">
        <div class="col col-12 col-sm-6 col-xs-12" *ngFor="let ref of referencesList?.references"
        [class.col-md-3]="ref.type != 'cta'"
          [class.col-md-6]="ref.type == 'cta'">
          <div class="reference" *ngIf="ref.type != 'cta'" (click)="goToDetails(ref)">
            <img [src]="ref.image.url" [alt]="ref.image.alt" [title]="ref.image.alt" />
            <div class="category">
              <img [src]="ref.category.image" [alt]="ref.category.name" />
            </div>
            <div class="description">
              <h2> {{ ref.title }} </h2>
              <p> {{ ref.category.name }} </p>
            </div>
          </div>
          <div class="cta" *ngIf="ref.type == 'cta'" (click)="goTo(referencesConfig.cta.link)">
            <div class="content" [style.background-image]="'url(' + referencesConfig?.cta?.bg_image + ')'">
              <h2> {{ referencesConfig?.cta?.title }} </h2>
              <p> {{ referencesConfig?.cta?.content }} </p>
              <a type="button" class="amc-btn-light btn-cta">
                {{ referencesConfig?.cta?.link?.title }}
                <img src="/assets/img/icon/arrow_cta.png" class="white mt-2" alt="Arrow CTA">
                <!-- <img src="/assets/img/icon/arrow_cta_grey.png" class="grey mt-2" alt="Arrow CTA"> -->
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="references-load-more" *ngIf="showLoadMore">
    <div class="row">
      <div class="col col-md-12 text-center">
        <button type="button" class="amc-btn load-more" (click)="loadMore()">
          {{ referencesConfig?.load_more }}
        </button>
      </div>
    </div>
  </section>

</div>