import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { LocalizationService } from "./localization.service";
import { Cookie } from "../models";
import { catchError, map } from "rxjs/operators";
import { RestService } from "./rest.service";
import { LoadingService } from "./loading.service";

@Injectable({
  providedIn: 'root'
})
export class CookiesService extends RestService {

  /**
   * cookieAccepted, by default get is false
   * @private
   */
  private _cookieAccepted = new BehaviorSubject<boolean>(false);
  public readonly cookieAccepted: Observable<boolean> = this._cookieAccepted.asObservable();

  /**
   * Cookie name
   */
  public readonly cookieName: string = 'cookieAccepted';

  /**
   * CookiesService constructor
   */
  constructor( private loadingService: LoadingService, http: HttpClient, localization: LocalizationService ) {
    super(http, localization);
    let cookieAccepted = this.getCookie(this.cookieName);
    this.acceptCookie(cookieAccepted == 'yes');
  }

  /**
   * get cookie config
   */
  getCookiesConfig(): Observable<any> {
    return this.http.get<Cookie>(CookiesService.apiHost + 'cookies').pipe(
      map((res: Cookie) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return CookiesService.handleError(err);
      })
    );
  }

  /**
   * accept cookies
   * @param cookie
   */
  public acceptCookie(cookie: boolean) {
    if (this._cookieAccepted.getValue() != cookie) {
      this._cookieAccepted.next(cookie);
    }
  }

  /**
   * set cookie byn name value and expire date
   *
   * @param name
   * @param value
   * @param days
   */
  setCookie(name, value, days = null) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  /**
   * get cookie by name
   *
   * @param name
   */
  getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');

    for(let i=0;i < ca.length;i++) {
      let c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  /**
   * erase cookie
   *
   * @param name
   */
  eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

}
