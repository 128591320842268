import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Hr } from '../../../models';
import { HomepageService, LocalizationService, CommonService } from "../../../services";

@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html',
  styleUrls: ['./hr.component.css']
})
export class HrComponent implements OnInit {

  hr: Hr;
  @ViewChildren('sliderShownDiv') sliderDivs: QueryList<ElementRef>;
  shownDivText = 0;

  /**
   * PersonaeComponent constructor
   * @param commonService
   * @param homepageService
   * @param localization
   */
  constructor(
    private commonService: CommonService,
    private homepageService: HomepageService,
    private localization: LocalizationService) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // watch selected lang, then translate home slider
    this.localization.lang.subscribe(() => this.getHRBloc());
  }


  /**
   * Get homepage personae blocs
   */
  getHRBloc(): void {
    this.homepageService.getHRBlocs()
      .subscribe((hr: Hr) => {
        this.hr = hr;
      });
  }

  /**
   * on div scroll
   * @param $event
   */
  onScroll($event) {
    let shown = this.sliderDivs.find(div => {
      const elementRect = div?.nativeElement.getBoundingClientRect();
      const containerRect = $event.target.getBoundingClientRect();
      return elementRect.left >= containerRect.left - 100 && elementRect.right <= containerRect.right + 100;
    });
    this.shownDivText = shown?.nativeElement.id;
  }

  /**
   * got to url
   * @param route
   */
  goTo(route: any) {
    return this.commonService.goToUrl(route);
  }
}
