import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  @Input() content: any;
  @ViewChild('video') video: any;
  maskVideo: boolean = true;

  constructor() { }

  ngOnInit(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  /**
   * Play video and hide mask
   */
  playVideo (): void {
    this.video.playVideo();
    this.maskVideo = false;
  }

}
