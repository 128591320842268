import { Component, OnInit, ViewChild } from '@angular/core';
import { NewsroomService, LocalizationService, FeedsConfigService } from "../../../services";
import {FeedsConfig, News, Newsroom} from "../../../models";
import { Router } from "@angular/router";
import { SlickCarouselComponent } from "ngx-slick-carousel";

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

  protected static currentLang;
  newsroomList: Newsroom;
  feedsConfig: FeedsConfig;
  @ViewChild("slickCarousel") slickCarousel: SlickCarouselComponent;

  slideConfig: object = {
    "arrows": false,
    "slide": 'div',
    "infinite": true,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "speed": 500,
    "autoplay": true,
    "adaptiveHeight": true
  };

  constructor (
    private router: Router,
    private newsroomService: NewsroomService,
    private feedsConfigService: FeedsConfigService,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    this.localization.lang.subscribe(lang => {
      SocialMediaComponent.currentLang = lang;
      this.getNewsroomList();
      this.getFeedsConfig();
    });
  }

  /**
   * Get Newsroom list
   */
  getNewsroomList(): void {
    this.newsroomService.getNewsroomList('0', '2', ['news'])
      .subscribe((newsroom: Newsroom) => {
        this.newsroomList = newsroom;
        this.newsroomService.getNewsroomList('0', '2', ['blog'])
          .subscribe((newsroom: Newsroom) => {
            newsroom.news?.forEach(function (item) {
              this.newsroomList.news.push(item);
            }, this)
            this.newsroomService.getNewsroomList('0', '2', ['social_post'])
              .subscribe((newsroom: Newsroom) => {
                newsroom.news?.forEach(function (item) {
                  this.newsroomList.news.push(item);
                  this.slickCarousel.initSlick();
                }, this)
              });
          });
      });
  }

  /**
   * Get Newsroom list
   */
  getFeedsConfig(): void {
    this.feedsConfigService.getFeedsConfig()
      .subscribe((newsroom: FeedsConfig) => {
        this.feedsConfig = newsroom;
      });
  }

  /**
   * Go to feed details
   * news: News
   */
  goToDetails( news: News ): void {
    if (news.type == 'social_post') {
      window.open(news.path, "_blank");
    } else {
      this.router.navigate([SocialMediaComponent.currentLang + '/newsroom/', news.path]).then(() => {
        let el = document.getElementById('top-hide-header');
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  }

  /**
   * Go to newsroom list
   * newsId:number
   */
  goToList(): boolean {
    this.router.navigate([SocialMediaComponent.currentLang + '/newsroom']).then(() => {
      let el = document.getElementById('top-hide-header');
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });

    return false;
  }

}
