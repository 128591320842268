import { Component, OnInit } from '@angular/core';
import { Sustainability } from '../../../models/';
import { LocalizationService, HomepageService, CommonService } from "../../../services";

@Component({
  selector: 'app-sustainability',
  templateUrl: './sustainability.component.html',
  styleUrls: ['./sustainability.component.css']
})
export class SustainabilityComponent implements OnInit {

  sustainability: Sustainability;

  /**
   * PersonaeComponent constructor
   * @param commonService
   * @param homepageService
   * @param localization
   */
  constructor(
    private commonService: CommonService,
    private homepageService: HomepageService,
    private localization: LocalizationService) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // watch selected lang, then translate home slider
    this.localization.lang.subscribe(() => this.getSustainabilityBlocs());
  }

  /**
   * got to url
   * @param route
   */
  goTo(route: any) {
    return this.commonService.goToUrl(route)
  }

  /**
   * Get homepage personae blocs
   */
  getSustainabilityBlocs(): void {
    this.homepageService.getSustainabilityBlocs()
      .subscribe((sustainability: Sustainability) => {
        this.sustainability = sustainability;
      });
  }

}
