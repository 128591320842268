import { Component, OnInit } from '@angular/core';
import { News, NewsConfig, Newsroom } from "../../models";
import { NewsroomService, LocalizationService, LoadingService, CommonService } from "../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-newsroom',
  templateUrl: './newsroom.component.html',
  styleUrls: ['./newsroom.component.css']
})
export class NewsroomComponent implements OnInit {

  protected static currentLang;
  newsroomConfig: NewsConfig;
  newsroomList: Newsroom;
  hotNews: News;
  showLoadMore: boolean = false;
  contentType: [string] = ['all'];
  tag: [string] = ['all'];
  date: string = 'all';
  dateString: string = 'all';

  contentTypeFilter: boolean = true;
  tagFilter: boolean = true;
  dateFilter: boolean = true;

  /**
   * Newsroom constructor
   * @param router
   * @param route
   * @param commonService
   * @param loadingService
   * @param newsroomService
   * @param localization
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private loadingService: LoadingService,
    private newsroomService: NewsroomService,
    private localization: LocalizationService,
    private navigation: NavigationService
  ) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let alias = "newsrooms"
      if (params['filter'] == 'content-type') {
        this.contentType = [(params['token'])];
        alias += "/" + params['filter'] + "/" + params['token'];
      }

      if (params['filter'] == 'theme') {
        this.tag = [(params['token'])];
        alias += "/" + params['filter'] + "/" + params['token'];
      }

      this.commonService.setStaticAliases(alias)

      this.localization.lang.subscribe(lang => {
        NewsroomComponent.currentLang = lang
        this.getNewsroomConfig();
      });
    });

    // watch selected lang, then translate Newsroom
  }

  /**
   * Get Newsroom Config
   */
  getNewsroomConfig(): void {
    this.newsroomService.getNewsroomConfig()
      .subscribe((newsConfig: NewsConfig) => {
        this.newsroomConfig = newsConfig;
        this.getHotNews();
      });
  }

  /**
   * Get Newsroom list
   */
  getNewsroomList(lenght: string, hotNews: News | null): void {
    this.newsroomService.getNewsroomList('0', lenght, this.contentType, this.tag, this.date)
      .subscribe((newsroom: Newsroom) => {
        this.loadingService.stopLoading();
        this.newsroomList = newsroom;
        this.showLoadMore = parseInt(newsroom.total) > parseInt(lenght);
        if (hotNews != null) {
          this.newsroomList.news.splice(6, 0, hotNews)
        }
      });
  }

  /**
   * Get Newsroom list
   */
  getHotNews(): void {
    this.newsroomService.getNewsroomList('0', '1', this.contentType, this.tag, this.date, 'true')
      .subscribe((newsroom: Newsroom) => {
        if (newsroom?.news) {
          this.getNewsroomList('10', newsroom.news[0])
        } else {
          this.getNewsroomList('12', null)
        }
      });
  }

  /**
   * Load more Newsroom
   */
  loadMoreNews(): void {
    this.loadingService.startLoading();
    let start = parseInt(this.newsroomList.start, 10) + parseInt(this.newsroomList.length, 10);
    this.newsroomService.getNewsroomList(start.toString(), '8', this.contentType, this.tag, this.date)
      .subscribe((newsroom: Newsroom) => {
        this.loadingService.stopLoading();
        this.showLoadMore = parseInt(newsroom.length) >= 8;
        newsroom.news?.forEach((news) => this.newsroomList.news.push(news));
        this.newsroomList.start = newsroom?.start;
        this.newsroomList.length = newsroom?.length;
      });
  }

  /**
   * filter news by content type
   */
  filterByContentType(contentType: string): void {
    this.loadingService.startLoading();
    if (this.contentType.includes(contentType)) {
      const index = this.contentType.indexOf(contentType);
      this.contentType.splice(index, 1);
    } else {
      this.contentType.push(contentType);
    }

    if ('all' == contentType || this.contentType.length <= 0) {
      this.contentType = ['all'];
    } else if (this.contentType.includes('all')) {
      const index = this.contentType.indexOf('all');
      this.contentType.splice(index, 1);
    }

    this.getHotNews();
    this.contentTypeFilter = false;
  }

  /**
   * filter news by tag
   */
  filterByTag(tag: string): void {
    this.loadingService.startLoading();
    if (this.tag.includes(tag)) {
      const index = this.tag.indexOf(tag);
      this.tag.splice(index, 1);
    } else {
      this.tag.push(tag);
    }

    if ('all' == tag || this.tag.length <= 0) {
      this.tag = ['all'];
    } else if (this.tag.includes('all')) {
      const index = this.tag.indexOf('all');
      this.tag.splice(index, 1);
    }

    this.getHotNews();
    this.tagFilter = false;
  }

  /**
   * filter news by tag
   */
  filterByDate(date: string): void {
    this.loadingService.startLoading();
    this.dateString = date;
    let dateFilter = new Date();

    switch (date) {
      case 'week':
        dateFilter.setDate(dateFilter.getDate() - 7);
        dateFilter.setHours(0, 0, 0, 0);
        break;

      case 'month':
        dateFilter.setMonth(dateFilter.getMonth() - 1);
        dateFilter.setHours(0, 0, 0, 0);
        break;

      case 'quarter':
        dateFilter.setMonth(dateFilter.getMonth() - 3);
        dateFilter.setHours(0, 0, 0, 0);
        break;

      case 'semester':
        dateFilter.setMonth(dateFilter.getMonth() - 6);
        dateFilter.setHours(0, 0, 0, 0);
        break;

      case 'year':
        dateFilter.setFullYear(dateFilter.getFullYear() - 1);
        dateFilter.setHours(0, 0, 0, 0);
        break;

      default: dateFilter = null;
    }

    this.date = dateFilter != null ? (dateFilter.valueOf() / 1000).toString() : 'all';
    this.getHotNews();
    this.dateFilter = false;
  }

  /**
   * format date
   *
   * @param date
   * @param format
   */
  formatDate(date: string, format: string = null) {
    let d = date.split(' ');
    switch (format) {
      case 'day': return d[0];
      case 'month': return d[1];
      case 'year': return d[2];
      default: return date;
    }
  }

  /**
   * Go to news details
   * newsId:number
   */
  goToDetails(news: News): void {
    if (news.type == 'social_post') {
      window.open(news.path, "_blank");
    } else if (news.type == 'leadership_paper') {
      this.loadingService.startLoading();
      this.router.navigate([NewsroomComponent.currentLang + '/form/', news.path], { queryParams: { contentType: this.contentType, tag: this.tag, date: this.date } }).then(() => {
        let el = document.getElementById('top-hide-header');
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    } else {
      this.loadingService.startLoading();
      this.router.navigate([NewsroomComponent.currentLang + '/newsroom/', news.path], {queryParams: {contentType: this.contentType, tag: this.tag, date: this.date}}).then(() => {
        let el = document.getElementById('top-hide-header');
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  }

  back(): void {
    this.navigation.back()
  }
}
