import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-free-text',
  templateUrl: './free-text.component.html',
  styleUrls: ['./free-text.component.css']
})
export class FreeTextComponent implements OnInit {

  @Input() content: any;

  constructor() { }

  ngOnInit(): void { }

  insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
}
