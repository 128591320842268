<div class="generic-cta" [style.background-image]="'url(\'../assets/img/generic_ca.png\')'">
  <div class="cta">
    <div class="cta-elements">
      <h2 class="cta-title"> {{ content?.title }} </h2>
      <p class="cta-content"> {{ content?.content }} </p>
    </div>
    <button type="button" class="amc-btn-light btn-cta" (click)="goTo(content?.link)">
      {{ content?.link?.title }}
      <img src="/assets/img/icon/arrow_cta.png" alt="arrow cta" class="arrow white">
    </button>
  </div>
</div>
