import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})

export class StripHtmlPipe implements PipeTransform {
  transform(value: string): any {
    value = value?.replace(/&nbsp;/gi, ''); // replace tags
    if (value.length > 150) {
      return value.replace(/<.*?>/g, '').substring(0, 150) + '...'; // replace tags
    } else {
      return value.replace(/<.*?>/g, ''); // replace tags
    }
  }
}
