import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.css']
})
export class ScrollToTopComponent implements OnInit {

  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= window.innerHeight)
      document.getElementById('scrollTop').style.display = 'block';
    else {
      document.getElementById('scrollTop').style.display = 'none';
    }
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.onWindowScroll, true);
  }

  // TODO: Cross browsing
  gotoTop() {
    let el = document.getElementById('top-hide-header');
    el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
