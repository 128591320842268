import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { LocalizationService } from './localization.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  protected static currentLang;
  protected static apiHost = environment.api_host + "/api/v1/";

  /**
   * constructor
   * @param http
   * @param localization
   */
  constructor(protected http: HttpClient, protected localization: LocalizationService) {
    this.localization.lang.subscribe({
      next(lang) {
        RestService.currentLang = lang;
        RestService.apiHost = environment.api_host + lang + "/api/v1/";
      }
    });
  }

  /**
   * handle error
   * @param error
   * @private
   */
  protected static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}`);
    }
    return throwError('Something bad happened; please try again later.');
  }


  /**
   * Append Matomo Script
   */
  appendMatomo(script_matomo: string) {
    let old_script = document.getElementById("matomo_script");
    if ( old_script ) {
      document.body.removeChild(old_script);
    }

    if ( script_matomo.length > 0 ) {
      let div = document.createElement('div');
      div.style.display = 'none';
      div.id = `matomo_script`;
      document.body.appendChild(div);
      document.getElementById('matomo_script').append(document.createRange().createContextualFragment(script_matomo));
    }
  }
}
