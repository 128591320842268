import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalizationService } from './localization.service';
import { Observable } from 'rxjs';
import { Search } from '../models';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from "./loading.service";


@Injectable({
  providedIn: 'root'
})
export class SearcheService extends RestService {

  constructor( private loadingService: LoadingService, http: HttpClient, localization: LocalizationService) {
    super(http, localization);
  }

  /**
   * get references list
   */
  search(needle, start= 0, length= 10): Observable<any> {
    const params = new HttpParams()
      .set('start', start.toString())
      .set('length', length.toString());

    return this.http.get<Search>(SearcheService.apiHost + 'search/' + needle, {params}).pipe(
      map((res) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return SearcheService.handleError(err);
      })
    );
  }
}
