import { Component, OnInit } from '@angular/core';
import { CookiesService, LocalizationService } from "../../../services";
import { Cookie } from "../../../models";

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  cookieConfig: Cookie;
  showCookieModal: boolean = false;
  cookieAccepted: boolean = false;

  constructor(
    private cookieService: CookiesService,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    this.cookieService.cookieAccepted.subscribe((cookie) => {
      this.cookieAccepted = cookie;
      let cookieAccepted = this.cookieService.getCookie(this.cookieService.cookieName);
      this.showCookieModal = cookieAccepted == null;
    });

    this.localization.lang.subscribe((lang) => {
      if(lang.length > 0) {
        this.getCookiesConfig()
      }
    });
  }

  /**
   * Get cookies config
   */
  getCookiesConfig(): void {
    this.cookieService.getCookiesConfig()
      .subscribe((cookie: Cookie) => {
        this.cookieConfig = cookie;

        if ( this.cookieConfig.cookie_matomo == 1 && this.cookieConfig.cookie_matomo_script.length > 0 ) {
          document.head.append(document.createRange().createContextualFragment(this.cookieConfig.cookie_matomo_script));
        }
      });
  }

  /**
   * hide cookie modal
   */
  hideCookieModal(): boolean {
    this.showCookieModal = false;
    return false;
  }

  /**
   * hide cookie modal
   */
  accept(): boolean {
    this.cookieService.setCookie(this.cookieService.cookieName, "yes");
    this.cookieService.acceptCookie(true);
    this.hideCookieModal();
    return false;
  }

  /**
   * hide cookie modal
   */
  decline(): boolean {
    this.cookieService.setCookie(this.cookieService.cookieName, "no", 1);
    this.cookieService.acceptCookie(false);
    this.hideCookieModal();
    return false;
  }

}
