<div class="app-references">
  <section class="slider_section">
    <div class="slider_container">
      <ngx-slick-carousel [config]="slideConfig">
        <div ngxSlickItem class="slider" *ngFor="let image of referenceDetails?.images;let i = index;">
          <img [src]="image.url" [alt]="image.title" width="100%" />
          <span class="scroll" (click)="scroll()">
            Scroll down to explore
          </span>
        </div>
      </ngx-slick-carousel>
    </div>
  </section>


  <section class="reference-breadcrumb">
    <div id="scoll-position"></div>
    <div class="row">
      <div class="col-sm-12">
        <a href="/{{ currentLang }}"><img src="../assets/img/icon/home.png" alt="home" /></a> |
        <span> <a routerLink="/{{ currentLang }}/references">References</a> </span> |
        <span> {{ referenceDetails?.title }} </span>
      </div>
    </div>
  </section>

  <section class="reference-details" id="details_references">
    <div class="row">
      <div class="col-lg-4 col-md-5 col-sm-12">
        <div class="attributes">
          <div class="category">
            <img [src]="referenceDetails?.category.image" [alt]="referenceDetails?.category.name" />
          </div>

          <div class="application">
            <h3>{{ referencesConfig?.application }}</h3>
            <div *ngIf="referenceDetails?.application" [innerHTML]="referenceDetails?.application | safeHtml"></div>
          </div>

          <div class="location">
            <h3>{{ referencesConfig?.location }}</h3>
            {{ referenceDetails?.location }}
          </div>

          <div class="architect">
            <h3>{{ referencesConfig?.architect }}</h3>
            {{ referenceDetails?.architect }}
          </div>

          <div class="photo-credits">
            <h3>{{ referencesConfig?.photo_credits }}</h3>
            {{ referenceDetails?.photo_credits }}
          </div>

          <div class="row">
            <div class="col col-md-6 back-to-list" (click)="goToList()">
              <img src="../assets/img/icon/back_to_references.png" alt="" />
              <h3> {{ referencesConfig?.back_to_projects }} </h3>
            </div>

            <div class="col col-md-6 related" (click)="goToRelated()">
              <img src="../assets/img/icon/white-share.png" alt="" />
              <h3> {{ referencesConfig?.related_products }} </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-7 col-sm-12 generic-content">

        <div class="col-sm-12 pagination">
          <a class="page-link prev pull-left text-capitalize" *ngIf="prevPath >= 0" (click)="goToReference(prevPath)">
            <img src="/assets/img/icon/arrow_cta_black.png" class="black" alt="Arrow CTA"> {{
            referencesList?.previous }}
          </a>
          <a class="page-link next pull-right text-capitalize" *ngIf="nextPath < referencesList?.total - 1"
            (click)="goToReference(nextPath)">
            {{ referencesList?.next }} <img src="/assets/img/icon/arrow_cta_black.png" class="black" alt="Arrow CTA">
          </a>
        </div>

        <div class="content pb-0">
          <h1>{{ referenceDetails?.title }}</h1>
        </div>
        <div class="content pt-0" [innerHTML]="referenceDetails?.body | safeHtml">
        </div>
      </div>
    </div>

    <div class="col-sm-12 pagination pagination-bottom">
      <ng-container *ngIf="prevPath >= 0; then prevref"></ng-container>
      <ng-container *ngIf="nextPath < referencesList?.total - 1; then nextref"></ng-container>

      <ng-template #prevref>
        <div class="div-ref prevRef">
          <h2>{{ prevTitle }}</h2>
          <a class="page-link prev pull-left text-capitalize" (click)="goToReference(prevPath)">
            <img src="/assets/img/icon/arrow_cta.png" class="black" alt="Arrow CTA"> {{
            referencesList?.previous_btm }}
          </a>
        </div>
      </ng-template>

      <ng-template #nextref>
        <div class="div-ref nextRef">
          <h2>{{ nextTitle }}</h2>
          <a class="page-link next pull-right text-capitalize" (click)="goToReference(nextPath)">
            {{ referencesList?.next_btm }} <img src="/assets/img/icon/arrow_cta.png" class="black" alt="Arrow CTA">
          </a>
        </div>
      </ng-template>
    </div>
    
  </section>
</div>
