import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { LocalizationService } from './localization.service';
import { Observable } from 'rxjs';
import { Footer } from '../models';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from "./loading.service";

@Injectable({
  providedIn: 'root'
})
export class FooterService extends RestService {

  constructor( private loadingService: LoadingService, http: HttpClient, localization: LocalizationService ) {
    super(http, localization);
  }

  /**
   * gel footer elements
   */
  getFooterElements(): Observable<any> {
    return this.http.get<Footer>(FooterService.apiHost + 'footer').pipe(
      map((res) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return FooterService.handleError(err);
      })
    );
  }


  /**
   * gel menu Link by token
   */
  getFooterMenuLinks(): Observable<any> {
    return this.http.get<any>(FooterService.apiHost + 'menu/main?menu=footer').pipe(
      map(res => res.links.length > 0 ? res.links : []),
      catchError((err) => {
        this.loadingService.stopLoading()
        return FooterService.handleError(err);
      })
    );
  }
}
