import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from "../../../services";

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.css']
})
export class CtaComponent implements OnInit {

  @Input() content: any;

  constructor (
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
  }

  /**
   * got to url
   * @param route
   */
  goTo(route: any) {
    return this.commonService.goToUrl(route)
  }
}
