import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { HomepageService, LocalizationService } from '../../../services';
import { Footprint } from '../../../models';
import { fromEvent } from "rxjs";

@Component({
  selector: 'app-footprint',
  templateUrl: './footprint.component.html',
  styleUrls: ['./footprint.component.css']
})
export class FootprintComponent implements OnInit {

  footprint: Footprint;
  selectedPoi: string;
  selectedItem: string;
  selectedCountry: string;
  countriesScrollTop: number = 0;
  scrolling: any;
  poiHovered: boolean = false;
  @ViewChild('countriesBloc', {static: true}) countriesBloc: ElementRef;
  @ViewChildren('use') use: QueryList<SVGUseElement>;

  /**
   * PersonaeComponent constructor
   * @param homepageService
   * @param localization
   */
  constructor(
    private homepageService: HomepageService,
    private localization: LocalizationService) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // watch selected lang, then translate home slider
    this.localization.lang.subscribe(() => this.getFootprintBlocs());
    fromEvent(this.countriesBloc.nativeElement, 'scroll')
      .subscribe((e: Event) => this.countriesScrollTop = e.target['scrollTop']);
  }

  /**
   * Get homepage personae blocs
   */
  getFootprintBlocs(): void {
    this.homepageService.getFootprintBlocs()
      .subscribe((footprint: Footprint) => {
        this.footprint = footprint;
      });
  }

  /**
   * select country
   * @param id
   * @param country
   */
  selectCountry(id: string, country: string): void {
    this.poiHovered = false;
    this.selectedItem = this.selectedPoi = id;
    this.selectedCountry = country;
    const offsets = document.getElementById(id);
    offsets?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  /**
   * hover POI
   * @param id
   * @param country
   */
  poiHover(id: string, country: string): void {
    this.poiHovered = true;
    this.selectedPoi = id;
    this.selectedCountry = country;
  }

  /**
   * Leave POI
   */
  poiLeave(): void {
    if (this.poiHovered) {
      this.poiHovered = false;
      this.selectedPoi = this.selectedCountry = null;
    }
  }

  /**
   * scroll countries div
   */
  scrollCountries(): void {
    this.countriesScrollTop += 10;
    this.countriesBloc.nativeElement.scrollTop = this.countriesScrollTop;
    this.scrolling = setTimeout(() => this.scrollCountries(), 10);
  }

  /**
   * scroll countries div
   */
  stopScrollCountries(): void {
    clearTimeout(this.scrolling);
  }
}
