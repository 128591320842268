<!-- include leader board component -->
<app-leader-board></app-leader-board>

<!-- include personae component -->
<app-personae></app-personae>

<!-- include social media component -->
<app-social-media></app-social-media>

<!-- include footprint component -->
<app-footprint></app-footprint>

<!-- include sustainability component -->
<app-sustainability></app-sustainability>

<!-- include projects component -->
<app-projects></app-projects>

<!-- include HR component -->
<app-hr></app-hr>
