<div class="cookie-policy" *ngIf="showCookieModal">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <a href="#" (click)="hideCookieModal()">
          <svg viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" class="svg-icon">
            <title id="Cross" lang="en">Cross icon</title>
            <g stroke="none" stroke-width="1" fill="#FFFFFF" fill-rule="evenodd">
              <g transform="translate(-1193.000000, -1647.000000)" fill="currentColor">
                <polygon
                  points="1211.61685 1648.41897 1210.58103 1647.38315 1202.30842 1655.65576 1194.03582 1647.38315 1193 1648.41897 1201.27261 1656.69158 1193 1664.96418 1194.03582 1666 1202.30842 1657.72739 1210.58103 1666 1211.61685 1664.96418 1203.34424 1656.69158"></polygon>
              </g>
            </g>
          </svg>
        </a>
        <div [innerHTML]="cookieConfig?.cookie_text?.value"></div>
        <button class="cookie-cta ml-0" (click)="accept()">
          {{ cookieConfig?.cookie_accept_text }}
        </button>
        <button class="cookie-cta" (click)="decline()">
          {{ cookieConfig?.cookie_decline_text }}
        </button>
      </div>
    </div>
  </div>
</div>
