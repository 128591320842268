import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading: Observable<boolean> = this._loading.asObservable();

  /**
   * start loading
   */
  public startLoading() {
    this._loading.next(true);
  }

  /**
   * stop loading
   */
  public stopLoading() {
    this._loading.next(false);
  }
}
