import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalizationService } from './localization.service';
import { Observable } from 'rxjs';
import { NewsConfig, Newsroom } from '../models';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from "./loading.service";


@Injectable({
  providedIn: 'root'
})
export class NewsroomService extends RestService {

  constructor( private loadingService: LoadingService, http: HttpClient, localization: LocalizationService ) {
    super(http, localization);
  }

  /**
   * get news config
   */
  getNewsroomConfig(): Observable<any> {
    return this.http.get<NewsConfig>(NewsroomService.apiHost + 'news/config').pipe(
      map((res: NewsConfig) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return NewsroomService.handleError(err);
      })
    );
  }

  /**
   * gel footer elements
   */
  getNewsroomList(start='0', length='10', type= ['all'], tag= ['all'], date= 'all', hot= 'false'): Observable<any> {
    const params = new HttpParams()
      .set('start', start)
      .set('length', length)
      .set('type', type.toString())
      .set('tag', tag.toString())
      .set('date', date)
      .set('hot', hot);


    return this.http.get<Newsroom>(NewsroomService.apiHost + 'news/list', {params}).pipe(
      map((res) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return NewsroomService.handleError(err);
      })
    );
  }

  /**
   * gel footer elements
   */
  getHotNews(): Observable<any> {
    const params = new HttpParams()
      .set('start', '0')
      .set('length', '1')
      .set('hot', 'true');

    return this.http.get<Newsroom>(NewsroomService.apiHost + 'news/list', {params}).pipe(
      map((res) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return NewsroomService.handleError(err);
      })
    );
  }

  /**
   * get news by id
   * @param node_id
   */
  getNewsroomDetails(node_id: number): Observable<any> {
    return this.http.get<any>(NewsroomService.apiHost + 'news/' + node_id).pipe(
      map((res) => {
        this.localization.setAliases(res.aliases);
        return res.news;
      }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return NewsroomService.handleError(err);
      })
    );
  }

}
