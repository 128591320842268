import { Component, OnInit } from '@angular/core';
import { LocalizationService, HomepageService, LoadingService } from '../../../services';
import { Slider } from '../../../models';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.css']
})
export class LeaderBoardComponent implements OnInit {

  // Slider object
  slider: Slider;

  // slider config
  slideConfig: object = {
    "slide": 'div',
    "speed": 500,
    "arrows": false,
    "autoplay": true,
    "autoplaySpeed": 7000,
    "adaptiveHeight": true
  };

  /**
   * LeaderBoardComponent constructor
   * @param loadingService
   * @param homepageService
   * @param localization
   */
  constructor(
    private loadingService: LoadingService,
    private homepageService: HomepageService,
    private localization: LocalizationService
  ) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // watch selected lang, then translate home slider
    this.localization.lang.subscribe(() => this.getLeaderBoardSlider());
  }

  /**
   * Get leader board slider
   */
  getLeaderBoardSlider(): void {
    this.homepageService.getLeaderBoardSlider()
      .subscribe((slider: Slider) => {
        this.loadingService.stopLoading();
        this.slider = slider;
        if ((this.slider.caption !== null) && (this.slider.caption !== undefined)) {
          this.slider.caption = this.slider.caption.split('##');
        }
        if (slider.is_video) {
          let chekForVideo = setTimeout(() => {
            let video = document.querySelector('video');
            if (video) {
              video.muted = true;
              video.play().then(r => clearTimeout(chekForVideo))
            }
          }, 500);
        }
      });
  }

  /**
   * Scroll to desired element
   * @param id, string
   */
  scroll(id: string) {
    let el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
