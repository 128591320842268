import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../environments/environment";

@Pipe({
  name: "safeHtml"
})

export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value) {
    if (null != value) {
      value = value?.replace(/&nbsp;/gi, ' ');
      const searchRegExp1 = new RegExp('> </p>', 'g');
      value = value?.replace(searchRegExp1, '>&nbsp;</p>');
      value = value?.replace('> </p>', '');
      const searchRegExp2 = new RegExp('src="/sites', 'g');
      value = value?.replace(searchRegExp2, 'src="' + environment.api_host +'/sites');
      value = value?.replace('<table', '<table class="table"');
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    return '';
  }
}
