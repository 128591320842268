import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService, HomepageService, LoadingService, LocalizationService } from '../../services';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.css']
})
export class NodeComponent implements OnInit, OnDestroy {
  node_id: number;
  node: any;
  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private loadingService: LoadingService,
    private homepageService: HomepageService,
    private localization: LocalizationService,
    private navigation: NavigationService
  ) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.node_id = params['node_id']; // (+) converts string 'id' to a number
      this.localization.lang.subscribe(() => this.getNodeDetails());
    });
  }

  getNodeDetails(): void {
    this.homepageService.getNodeDetails(this.node_id)
      .subscribe((node: any) => {
        this.loadingService.stopLoading();
        this.node = node.node;
        let type = this.node.type[0].target_id;
        if(this.node.type && ['page', 'article'].indexOf(type) < 0) {
          if (type == 'news') {
            type = 'newsroom';
          } else if (type == 'leadership_paper') {
            type = 'form';
          }

          //const id = this.node.nid[0].value;
          const id = this.node.path[0].alias;
          this.commonService.redirectNode(type, id, '#')
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /** Newsletter form submission routing */
  back(): void {
    this.navigation.back()
  }
}
