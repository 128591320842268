import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Link } from '../models';
import { map, catchError, filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  /**
   * lang, by default get lang from config
   * @private
   */
  private _lang = new BehaviorSubject<string>('');
  public readonly lang: Observable<string> = this._lang.asObservable();

  /**
   * aliases translated for curent url
   * @private
   */
  private _aliases = new BehaviorSubject<any>(['']);
  public readonly aliases: Observable<any> = this._aliases.asObservable();

  /**
   * LocalizationService constructor
   * @param router
   * @param route
   * @param http
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    let params = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.getLeafRoute(this.router.routerState.root).snapshot.params)
    );

    params.subscribe(paramsList => this.setLang(paramsList.lang));
  }

  /**
   * Set lang
   * @param lang
   */
  public setLang(lang: string) {
    if (this._lang.getValue() != lang && lang.length > 0) {
      this._lang.next(lang);
    }
  }

  /**
   * Set aliases
   * @param aliases
   */
  public setAliases(aliases: any) {
    this._aliases.next(aliases);
  }


  /**
   * Extract response data
   * @param res
   * @private
   */
  private static extractLanguages(res: any): Array<Link> {
    return res.languages;
  }


  /**
   * gel menu Link by token and parent id
   */
  getActiveLanguages(): Observable<any> {
    return this.http.get<Link>(environment.api_host + 'api/v1/languages').pipe(
      map(LocalizationService.extractLanguages),
      catchError(LocalizationService.extractLanguages)
    );
  }

  /**
   * getLeafRoute
   * @param route
   * @private
   */
  getLeafRoute(route: ActivatedRoute): ActivatedRoute {
    if (route === null) return null; //or throw ?
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  /**
   * insert caption
   */
  insertCaptions() {
    let imgs = document.querySelectorAll("img");
    imgs.forEach(function (img) {
      let imgCaption = img.getAttribute("data-caption");
      if(imgCaption != null) {
        let newFigure = document.createElement("figure");
        newFigure.setAttribute('style', 'width:' + String(img.naturalWidth) + 'px');
        newFigure.setAttribute('data-align', img.getAttribute("data-align"));

        let newFigCaption = document.createElement("figcaption");
        newFigCaption.innerHTML = imgCaption;
        newFigCaption.setAttribute('data-align', img.getAttribute("data-align"));
        newFigCaption.setAttribute('style', 'width:' + String(img.naturalWidth) + 'px');

        img.parentNode.insertBefore(newFigure, img.nextSibling);
        newFigure.appendChild(img);
        newFigure.appendChild(newFigCaption);
      }
    })
  }
}
