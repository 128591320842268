import { Component, OnInit } from '@angular/core';
import { CommonService, LoadingService } from "../../services";
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private loadingService: LoadingService,
    private navigation: NavigationService
  ) { }

  ngOnInit(): void {
    this.commonService.setStaticAliases("home")
    this.loadingService.stopLoading();
  }

  /** Newsletter form submission routing */
  back(): void {
    this.navigation.back()
  }
}
