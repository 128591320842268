<section class="generic-content newsletter-form" *ngIf="form && !sended">
  <form class="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between" name="newsletterForm"
    #newsletterForm="ngForm" (ngSubmit)="submit(newsletterForm)">
    <div class="headings">
      <h3>{{ form?.config?.title }}</h3>
      <p class="mb-lg-0">{{ form?.config?.subtitle }}</p>
    </div>

    <div class="p-0">
      <div *ngFor="let field of form?.fields" [ngSwitch]="field.type">
        <div class="form-group" *ngSwitchCase="'textfield'">
          <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
            [placeholder]="field.required ? field.title + '*' : field.title" ngModel />
        </div>

        <div class="form-group mb-2" *ngSwitchCase="'email'">
          <input type="text" class="form-control" [name]="field.id" [id]="field.id" [required]="field.required"
            [placeholder]="field.required ? field.title + '*' : field.title" ngModel />
        </div>

        <div class="form-group" *ngSwitchCase="'select'">
          <select class="form-control amc-select" [name]="field.id" [id]="field.id" [required]="field.required">
            <option [value]="" [selected]="true">
              {{ field.title }}
            </option>
            <option *ngFor="let option of field.options" [value]="option.id">
              {{ option.value }}
            </option>
          </select>
        </div>

        <div class="form-group mb-0" *ngSwitchCase="'checkbox'">
          <label class="checkbox-container d-flex align-items-center justify-content-end flex-row-reverse mb-lg-0">
            {{ field.title }}
            <input type="checkbox" class="mr-2" [name]="field.id" [id]="field.id" [required]="field.required" ngModel>
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>

    <!-- <div class="col-sm-12 mb-3" *ngIf="cookieAccepted && recapcha">
      <re-captcha class="captcha_class" (resolved)="resolved($event)" siteKey="{{ recapchaSiteKey }}"></re-captcha>
    </div>

    <div class="contact_btn col-sm-12 mb-3" *ngIf="!cookieAccepted">
      <div class="text-white" [innerHTML]="cookieConfig?.cookie_form_text?.value"></div>
      <button type="button" class="form_btn amc-btn-light" (click)="accept()">
        {{ cookieConfig?.cookie_accept_text }} Cookies
      </button>
    </div> -->

    <div class="contact_btn">
      <button type="submit" class="amc-btn-light-contact d-flex w-100">
        {{ form?.config?.submit_text }}
        <img src="/assets/img/icon/arrow_cta.png" class="white ml-3" alt="Arrow CTA">
      </button>
    </div>
  </form>
</section>

<section class="footer" id="footer">
  <div class="content_footer">
    <div class="row">
      <div class="col-lg-3 col-md-12 item_footer">
        <h5 class="h5">
          ArcelorMittal Construction
        </h5>
        <p> {{ footer?.description }} </p>
      </div>

      <div *ngFor="let column of footer?.columns;" class="col-lg col-md-4 col-6 item_footer">
        <ul class="mt-4">
          <li *ngFor="let link of column.links" [ngClass]="{'mb-3': isLast}">
            <a (click)="goTo(link)" href="#"> {{ link.title }} </a>
          </li>
        </ul>
      </div>

      <ul class="item_footer_media mt-4 col-1">
        <li>
          <!-- <a class="mb-1">{{ footer?.social?.title }}</a> -->
          <a class="m-1" href="{{ social.uri }}" title="{{ social.title }}" target="_blank"
            *ngFor="let social of footer?.social?.list">
            <i class="fa fa-{{ social.title }}"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>

<div class="footer_copyright">
  <div class="content">
    <div class="left">
      <div *ngFor="let link of links; let isLast = last">
        <a (click)="goTo(link)" href="#">
          <span> {{ link.title }} </span>
        </a>
        <span *ngIf="!isLast"> - </span>
      </div>

      <p> © {{ toDay | date:'yyyy' }} - {{ footer?.copyright }} </p>
    </div>

    <div class="right">
      <img src="/assets/img/amc_logo_white.png" alt="">
    </div>
  </div>
</div>