import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-facts',
  templateUrl: './facts.component.html',
  styleUrls: ['./facts.component.css']
})
export class FactsComponent implements OnInit {

  @Input() content: any;
  animated: boolean = false;

  constructor() { }

  ngOnInit(): void {
    document.addEventListener('scroll', () => {
      if (!this.animated) {
        let facts = document.getElementById('facts');
        if (this.isInViewport(facts)) {
          this.incEltNbr();
          this.animated = true;
        }
      }
    }, true);
  }

  /**
   * incrase fact numbers
   */
  incEltNbr() {
    let facts = document.getElementsByTagName('fact');
    for (let j = 0; j < facts.length; j++) {
      let elt = facts[j];
      let endNbr = Number(elt.innerHTML);
      this.incNbrRec(0, endNbr, elt);
    }
  }

  /**
   * incrase fact numbers
   *
   * @param i
   * @param endNbr
   * @param elt
   */
  incNbrRec(i, endNbr, elt) {
    let interval = setInterval(function () {
      i++; elt.innerHTML = i;

      if (i >= endNbr) {
        clearInterval(interval);
      }
    }, 1);
  }

  /**
   * is element in viewport
   * @param elem
   */
  isInViewport(elem) {
    if (elem != undefined) {
      let bounding = elem.getBoundingClientRect();
      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    return false;
  };

}
