<section class="sustainability" style="background-image: url({{ sustainability?.image.url }})">
  <div class="content_sustainability">
    <h3 class="title">{{ sustainability?.title }}</h3>
    <div class="content" [innerHTML]="sustainability?.content"></div>
    <div class="btn_sustainability">
      <a (click)="goTo(sustainability?.link)" href="#" class="amc-btn">
        {{ sustainability?.link?.title }}
        <img src="/assets/img/icon/arrow_red.png" class="orange" alt="Arrow red">
        <img src="/assets/img/icon/arrow_cta.png " class="grey" alt="Arrow red">
      </a>
    </div>
  </div>
</section>
