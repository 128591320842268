import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { CommonService, LoadingService, LocalizationService, ReferenceService } from "../../services";
import { References, Reference, ReferencesConfig } from "../../models";
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.css']
})
export class ReferencesComponent implements OnInit {

  currentLang;
  showLoadMore: boolean = false;
  referencesList: References;
  referencesConfig: ReferencesConfig;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private loadingService: LoadingService,
    private localization: LocalizationService,
    private referenceService: ReferenceService,
    private navigation: NavigationService
  ) {
  }

  ngOnInit(): void {
    // watch selected lang, then translate references
    this.localization.lang.subscribe(lang => {
      this.currentLang = lang
      this.getReferencesConfig();
      this.getReferencesList('0', '10');
      this.commonService.setStaticAliases("references")
    });
  }

  /**
   * Get References Config
   */
  getReferencesConfig(): void {
    this.referenceService.getReferencesConfig()
        .subscribe((referencesConfig: ReferencesConfig) => this.referencesConfig = referencesConfig);
  }

  /**
   * Get Newsroom list
   */
  getReferencesList(start: string, lenght: string): void {
    this.referenceService.getReferencesList(start, lenght)
      .subscribe((references: References) => {
        this.loadingService.stopLoading();
        this.referencesList = references;
        this.showLoadMore = references.length >= 10;
        if (this.referencesConfig && this.referencesConfig.cta.title) {
          let cta: Reference  = this.createCtaReference();
          this.referencesList.references.splice(6, 0, cta)
        }
      });
  }

  /**
   * Go to reference details
   * reference: Reference
   */
  goToDetails( reference: Reference ): void {
    this.loadingService.startLoading();
    this.router.navigate([this.currentLang + '/reference/', reference.path]).then(() => {
      let el = document.getElementById('top-hide-header');
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }

  /**
   * Load more Newsroom
   */
  loadMore(): void {
    let start = parseInt(this.referencesList.start, 10) + parseInt(this.referencesList.length, 10);
    this.referenceService.getReferencesList(start.toString(), '12')
      .subscribe((references: References) => {
        this.showLoadMore = references.length >= 12;
        references.references?.forEach((reference) => this.referencesList.references.push(reference));
        this.referencesList.start = references?.start;
        this.referencesList.length = references?.length;
      });
  }

  /**
   * Go to CTA
   * route: any
   */
  goTo(route: any) {
    return this.commonService.goToUrl(route);
  }

  /**
   * Create Cta Reference
   */
  createCtaReference(): Reference {
    return new class implements Reference {
      application;
      architect;
      body;
      script_matomo;
      category: { name; image };
      date;
      id;
      caption;
      image: { url; title; alt };
      images: [{ url; title; link }];
      location;
      photo_credits;
      title;
      path;
      type = 'cta';
      related_products: { title; external; url };
    };
  }

  /** Newsletter form submission routing */
  back(): void {
    this.navigation.back()
  }
}
