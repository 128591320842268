import { Component, OnInit } from '@angular/core';
import { Personae } from '../../../models';
import { HomepageService, LocalizationService, CommonService} from "../../../services";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  projects: Personae;

  /**
   * ProjectsComponent constructor
   * @param commonService
   * @param homepageService
   * @param localization
   */
  constructor (
    private commonService: CommonService,
    private homepageService: HomepageService,
    private localization: LocalizationService) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // watch selected lang, then translate home slider
    this.localization.lang.subscribe(() => this.getProjectsBlocs());
  }

  /**
   * got to url
   * @param route
   */
  goTo(route: any) {
    return this.commonService.goToUrl(route)
  }

  /**
   * Get homepage projects blocs
   */
  getProjectsBlocs(): void {
    this.homepageService.getProjectsBlocs()
      .subscribe((personae: Personae) => {
        this.projects = personae;
      });
  }
}
