<section>
  <div class="content_Why">
    <div class="row">
      <div class="why-div col-lg-5 col-md-6 col-12">
        <div class="Why_field" style="background-image: url({{ hr?.image.url }})">
          <h4> {{ hr?.title }} </h4>
          <p> <span> {{ hr?.subtitle }} </span> {{ hr?.content }} </p>
        </div>
      </div>

      <div class="col-lg-7 col-md-6 col-12">
        <div class="wrapper" (scroll)="onScroll($event)">
          <div class="carousel_3">
            <div *ngFor="let slide of hr?.slider; let index = index;" class="card"
                 (click)="goTo(slide.link)" [id]="index" #sliderShownDiv>
              <div class="imag_Caros_second">
                <img src="{{ slide.image.url }}" class="w-100" alt="{{ slide.image.title }}">
              </div>
              <div class="fild_info_item" *ngIf="index == shownDivText">
                <div class="Title">
                  <h4> {{ slide.title }} </h4>
                </div>

                <div class="btn_Addtocard">
                  <div class="content_btn"> {{ slide.content }} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
