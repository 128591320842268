<section class="generic-header" *ngIf="genericDetails" [style.background-image]="'url(' + genericDetails?.header_image?.url + ')'">
  <div class="text-container">
    <h1 class="title"> {{ genericDetails?.title }} </h1>
    <div class="content">
      {{ genericDetails?.subtitle }}
    </div>
  </div>
</section>

<section class="generic-breadcrumb">
  <div class="row">
    <div class="col-sm-12">
      <a href="/"><img src="../assets/img/icon/home.png"  alt="home"/></a>  |
      <span> {{ genericDetails?.title }} </span>
    </div>
  </div>
  <span class="row col-sm-12 generic-caption">{{ genericDetails?.caption }}</span>
</section>

<section class="generic-content" *ngIf="genericDetails || genericDetails?.contents">
  <div class="row">
    <div class="block col-md-12 col-sm-12">
      <app-faq class="row"></app-faq>
    </div>
    <div *ngFor="let content of genericDetails?.contents"  [ngSwitch]="content.type"
         class="block col-md-{{ content.width}} col-sm-12">
      <app-faq class="row" *ngSwitchCase="'faq_list'" [content]="content"></app-faq>
      <app-free-text class="row" *ngSwitchCase="'free_text'" [content]="content"></app-free-text>
      <app-bgcolor-text *ngSwitchCase="'full_width_text_with_bgcolor'" [content]="content"></app-bgcolor-text>
      <app-related-news *ngSwitchCase="'related_news'" [content]="content"></app-related-news>
      <app-quote *ngSwitchCase="'quote'" [content]="content"></app-quote>
      <app-video *ngSwitchCase="'video_youtube'" [content]="content"></app-video>
      <app-cta *ngSwitchCase="'cta'" [content]="content"></app-cta>
      <app-facts *ngSwitchCase="'facts'" [content]="content"></app-facts>
      <app-slider *ngSwitchCase="'images_slider'" [content]="content"></app-slider>
    </div>
  </div>
</section>

