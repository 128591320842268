import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { LocalizationService } from './localization.service';
import { Observable } from 'rxjs';
import { FeedsConfig } from '../models';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from "./loading.service";


@Injectable({
  providedIn: 'root'
})
export class FeedsConfigService extends RestService {

  constructor( private loadingService: LoadingService, http: HttpClient, localization: LocalizationService ) {
    super(http, localization);
  }

  /**
   * get feeds config
   */
  getFeedsConfig(): Observable<any> {
    return this.http.get<FeedsConfig>(FeedsConfigService.apiHost + 'homepage/feeds').pipe(
      map((res: FeedsConfig) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return FeedsConfigService.handleError(err);
      })
    );
  }
}
