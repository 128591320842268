import { Injectable } from '@angular/core';
import { Link } from "../models";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { LocalizationService } from "./localization.service";
import { RestService } from "./rest.service";
import { LoadingService } from "./loading.service";

@Injectable({
  providedIn: 'root'
})
export class CommonService extends RestService {

  /**
   * constructor
   * @param router
   * @param http
   * @param loadingService
   * @param localization
   */
  constructor (
    private router: Router,
    private loadingService: LoadingService,
    http: HttpClient,
    localization: LocalizationService
  ) {
    super(http, localization);
  }

  /**
   * go to link
   * @param route
   */
  goToLink(route: Link) {
    if (route.external) {
      this.redirectExternal(route.url);
    }

    let el = document.getElementById('top-hide-header');
    let token = [route.type];

    if (route.type) {
      token = route?.type?.split(',');
    }

    let path = token[0];
    if (path == 'news') {
      path = 'newsroom';
    } else if (path == 'leadership_paper') {
      path = 'form';
    } else if (['page', 'article', 'social_post'].indexOf(path) >= 0) {
      path = 'node';
    }

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    switch (path) {
      case "newsrooms":
        this.loadingService.startLoading();
        if (token.length == 1) {
          this.router.navigate([CommonService.currentLang + '/newsroom'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        } else {
          this.router.navigate([CommonService.currentLang + '/newsroom/' + token[1] + '/' + token[2] ])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        }
        break;

      case "references":
        this.loadingService.startLoading();
        this.router.navigate([CommonService.currentLang + '/references'])
          .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        break;

      case "contact":
        this.loadingService.startLoading();
        this.router.navigate([CommonService.currentLang + '/contact'])
          .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        break;

      case "newsletter-tags":
        this.loadingService.startLoading();
        this.router.navigate([CommonService.currentLang + '/cat-subscribe'])
          .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        break;

      case "newsletter-unsubscription":
        this.loadingService.startLoading();
        this.router.navigate([CommonService.currentLang + '/unsubscribe'])
          .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        break;

      case "newsletter-update":
        this.loadingService.startLoading();
        this.router.navigate([CommonService.currentLang + '/preferences'])
          .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        break;

      default:
        this.redirectNode(path, route?.path, route.url);
    }

    return false;
  }

  /**
   * go to url
   * @param route
   */
  goToUrl(route: any) {
    if (route.external) {
      return this.redirectExternal(route.url);
    }

    if (route.url != '#') {
      this.loadingService.startLoading();
      let el = document.getElementById('top-hide-header');
      let url = route.url.split('__');
      let path = url[0];

      if (path == 'news') {
        path = 'newsroom';
      } else if (path == 'leadership_paper') {
        path = 'form';
      } else if (['page', 'article', 'social_post'].indexOf(path) >= 0) {
        path = 'node';
      }

      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([CommonService.currentLang + '/' + path + '/' + url[1]])
        .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
    }

    return false;
  }

  /**
   * newsroom filter redirect
   */
  redirectNewsRooms(type, el) {
    this.loadingService.startLoading();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let token  = type.split(',');
    if (token.length == 1) {
      this.router.navigate([CommonService.currentLang + '/newsroom'])
        .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
    } else {
      this.router.navigate([CommonService.currentLang + '/newsroom/' + token[1] + '/' + token[0] ])
        .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
    }
  }

  /**
   * redirect node by type
   */
  redirectNode(type, id, url) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let el = document.getElementById('top-hide-header');
    switch (type) {
      case "newsroom":
        this.loadingService.startLoading();
        this.router.navigate([CommonService.currentLang + '/newsroom/' + id])
          .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        break;

      case "reference":
        this.loadingService.startLoading();
        this.router.navigate([CommonService.currentLang + '/reference/' + id])
          .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        break;

      case "generic":
        this.loadingService.startLoading();
        this.router.navigate([CommonService.currentLang + '/generic/' + id])
          .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        break;

      case "leadership_paper":
        this.loadingService.startLoading();
        this.router.navigate([CommonService.currentLang + '/form/' + id])
          .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        break;

      default:
        if (url != '#') {
          this.loadingService.startLoading();
          this.router.navigate([CommonService.currentLang + '/node/' + id])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
        }
    }
  }

  /**
   * redirect node by type
   */
  redirectExternal(url) {
    if (url.includes('amc.front')) {
      const patern = url.split('#');
      const params = patern[1].split(',');

      let el = document.getElementById('top-hide-header');
      switch (params[0]) {
        case "newsrooms":
          this.loadingService.startLoading();
          if (params.length == 1) {
            this.router.navigate([CommonService.currentLang + '/newsroom'])
              .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          } else {
            this.router.navigate([CommonService.currentLang + '/newsroom/' + params[1] + '/' + params[2] ])
              .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          }
          break;

        case "references":
          this.loadingService.startLoading();
          this.router.navigate([CommonService.currentLang + '/references'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "contact":
          this.loadingService.startLoading();
          this.router.navigate([CommonService.currentLang + '/contact'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "newsletter-tags":
          this.loadingService.startLoading();
          this.router.navigate([CommonService.currentLang + '/cat-subscribe'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "newsletter-unsubscription":
          this.loadingService.startLoading();
          this.router.navigate([CommonService.currentLang + '/unsubscribe'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "newsletter-update":
          this.loadingService.startLoading();
          this.router.navigate([CommonService.currentLang + '/preferences'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;
      }
    } else {
      window.open(url, "_blank");
    }

    return false;
  }

  /**
   * download sitemap.xml
   */
  downloadXml() {
    return this.http.get(CommonService.apiHost + `sitemap`, {responseType: 'blob'});
  }

  /**
   * change land and stay in current artice
   */
  redirectUrl(type, alias, lang) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    let el = document.getElementById('top-hide-header');

    if (alias == false || alias == "home") {
      this.loadingService.startLoading();
      this.router.navigate([lang])
        .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
    } else {
      switch (type) {
        case "newsrooms":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/newsroom'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "newsrooms/content-type/news":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/newsroom/content-type/news'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "newsrooms/content-type/blog":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/newsroom/content-type/blog'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "newsrooms/content-type/leadership_paper":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/newsroom/content-type/leadership_paper'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "newsrooms/content-type/social_post":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/newsroom/content-type/social_post'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "newsroom":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/newsroom/' + alias])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "references":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/references'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "reference":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/reference/' + alias])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "generic":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/generic/' + alias])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "leadership_paper":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/form/' + alias])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "contact":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/contact'])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
          break;

        case "newsletter-tags":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/cat-subscribe'])
            .then(() => el?.scrollIntoView({ behavior: 'smooth', block: 'center' }));
          break;

        case "newsletter-unsubscription":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/unsubscribe'])
            .then(() => el?.scrollIntoView({ behavior: 'smooth', block: 'center' }));
          break;

        case "newsletter-update":
          this.loadingService.startLoading();
          this.router.navigate([lang + '/preferences'])
            .then(() => el?.scrollIntoView({ behavior: 'smooth', block: 'center' }));
          break;

        default:
          this.loadingService.startLoading();
          this.router.navigate([lang + '/node/' + alias])
            .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
      }
    }
  }


  setStaticAliases(alias) {
    this.localization.setAliases({
      "en": {
        "alias": alias,
        "type": alias
      },
      "fr": {
        "alias": alias,
        "type": alias
      },
      "de": {
        "alias": alias,
        "type": alias
      },
      "es": {
        "alias": alias,
        "type": alias
      },
      "pl": {
        "alias": alias,
        "type": alias
      }
    });
  }
}
