import { Component, OnInit } from '@angular/core';
import { CommonService, LoadingService } from "../../services";
import { Router } from "@angular/router";

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {

  constructor(
    private router: Router,
    private commonService: CommonService,
    private loadingService: LoadingService
  ) {
  }

  ngOnInit(): void {
    this.commonService.downloadXml().subscribe((data) => {
      this.loadingService.stopLoading();
      let fileToExport = new Blob([data], {type: "text/xml"});
      let a = document.createElement("a");
      a.href = URL.createObjectURL(fileToExport);
      a.target = "_blank";
      a.download = "sitemap.xml";
      a.click();

      // got to home page
      let el = document.getElementById('top-hide-header');
      this.router.navigate(['/'])
        .then(() => el?.scrollIntoView({behavior: 'smooth', block: 'center'}));
    });
  }
}
