<div class="app-search">
  <section class="search-breadcrumb">
    <div class="row">
      <div class="col-sm-12">
        <h2 *ngIf="searches?.search_text">{{ searches?.search_text.replace('__nb__', searches?.total ) }} « {{ needle }} » : </h2>
        <div class="col col-md-6 col-sm-12 search-field">
          <input class="form-control" type="text" placeholder="{{ searches?.search_keyword }}" [(ngModel)]="needle"
                 (keydown.enter)="doSearch()" />
          <div class="close" (click)="needle = ''"></div>
        </div>
      </div>
    </div>
  </section>

  <section class="search-breadcrumb">
    <div class="row">
      <div class="col-sm-12">
        <a href="/{{ currentLang }}/"><img src="../assets/img/icon/home.png" alt="home" /></a> |
        <span> {{ searches?.search_keyword }} </span>
      </div>
    </div>
  </section>

  <section class="generic-content pb-4">
    <div class="row">
      <div class="col-sm-12 pagination d-block">
        <a class="page-link prev pull-left text-capitalize" *ngIf="activePage > 1" href="#"
           (click)="goToPage(activePage - 1)" >
          <img src="/assets/img/icon/arrow_cta_black.png" class="black" alt="Arrow CTA"> {{ searches?.previous }}
        </a>
        <a class="page-link next pull-right text-capitalize" *ngIf="activePage < pages.length" href="#"
           (click)="goToPage((activePage - 0) + 1)" >
          {{ searches?.next }} <img src="/assets/img/icon/arrow_cta_black.png" class="black" alt="Arrow CTA">
        </a>
      </div>

      <div class="col-sm-12 content">
        <div class="result mb-5" *ngFor="let result of searches?.results" (click)="goTo(result)">
          <h3> {{ result.title }}
            <code> | </code>
            <span> {{ result.type.replace('_', ' ') }} </span>
          </h3>
          <p *ngIf="result.summary"> {{ result.summary }} </p>
          <p *ngIf="!result.summary && result.body" [innerHTML]="result.body | stripHtml"> </p>
        </div>
      </div>
    </div>

    <nav aria-label="navigation">
      <div class="pagination justify-content-center pb-3">
        <span class="page-item" *ngIf="activePage > 1">
          <a class="page-link prev" href="#" (click)="goToPage(activePage - 1)">
            <img src="/assets/img/icon/arrow_cta_black.png" class="white" alt="Arrow CTA">
          </a>
        </span>

        <span class="page-item" *ngFor="let page of pages" [class.active]="page == activePage">
          <a class="page-link" href="#" (click)="goToPage(page)">{{ page }}</a>
        </span>

        <span class="page-item" *ngIf="activePage < pages.length">
          <a class="page-link next" href="#" (click)="goToPage((activePage - 0) + 1)">
            <img src="/assets/img/icon/arrow_cta_black.png" class="white" alt="Arrow CTA">
          </a>
        </span>
      </div>
    </nav>
  </section>
</div>
