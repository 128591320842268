<section class="personae_section" id="personae">
  <div class="row">
    <div class="card-div col-lg-3 col-md-6 col-sm-12" *ngFor="let bloc of personae?.blocs">
      <div class="card">
        <img class="card-img-top" src="{{ bloc.image.url }}" alt="{{ bloc.image.title }}">
        <div class="card-body">
          <div class="card-title">
            {{ bloc.title }}
          </div>
          <p class="card-text">{{ bloc.content }}</p>
          <a class="amc-btn-light" (click)="goTo(bloc.link)" href="#">
            {{ bloc.link.title }}
            <img src="/assets/img/icon/arrow_cta.png" alt="arrow cta" class="arrow white mt-2">
            <!-- <img src="/assets/img/icon/arrow_cta_grey.png" alt="arrow cta" class="arrow grey mt-2"> -->
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
