import { Component, OnInit } from '@angular/core';
import { HomepageService, LocalizationService, CommonService } from '../../../services';
import { Personae } from '../../../models';

@Component({
  selector: 'app-personae',
  templateUrl: './personae.component.html',
  styleUrls: ['./personae.component.css']
})
export class PersonaeComponent implements OnInit {

  personae: Personae;

  /**
   * PersonaeComponent constructor
   * @param commonService
   * @param homepageService
   * @param localization
   */
  constructor(
    private commonService: CommonService,
    private homepageService: HomepageService,
    private localization: LocalizationService
  ) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    // watch selected lang, then translate home slider
    this.localization.lang.subscribe(() => this.getPersonaBlocs());
  }

  /**
   * got to url
   * @param route
   */
  goTo(route: any) {
    return this.commonService.goToUrl(route);
  }

  /**
   * Get homepage personae blocs
   */
  getPersonaBlocs(): void {
    this.homepageService.getPersonaBlocs()
      .subscribe((personae: Personae) => {
        this.personae = personae;
      });
  }
}
