import { Component, OnInit } from '@angular/core';
import { Footer } from "../../../models";
import { Router } from "@angular/router";
import { CommonService, FooterService, LocalizationService } from "../../../services";

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.css']
})
export class SocialShareComponent implements OnInit {

  showSocial: boolean = false;
  footer: Footer;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private footerService: FooterService,
    private localization: LocalizationService) {
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    window.addEventListener('scroll', this.onWindowScroll, true);
    // watch selected lang, then translate home slider
    this.localization.lang.subscribe((lang) => {
      if(lang.length > 0) {
        this.getFooter()
      }
    });
  }

  /**
   * Get footer elements
   */
  getFooter(): void {
    this.footerService.getFooterElements()
      .subscribe((footer: Footer) => {
        this.footer = footer;
      });
  }

  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= (window.innerHeight - (window.innerHeight / 4)))
      document.getElementById('shareBlock').style.display = 'block';
    else {
      document.getElementById('shareBlock').style.display = 'none';
    }
  }
}
