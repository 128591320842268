<div id="facts" class="generic-facts"  [style.background-image]="'url(' + content?.bg_image + ')'">
  <div class="row">
    <div class="col-sm-3" *ngFor="let fact of content?.facts" #facts>
      <div class="fact">
        <h2 id="fact1"><fact>{{ fact.number }}</fact></h2>
        <p>{{ fact.text }}</p>
      </div>
    </div>
  </div>
</div>
