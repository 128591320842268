import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalizationService } from './localization.service';
import { Link, Settings } from '../models';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RestService } from './rest.service';
import { LoadingService } from "./loading.service";

@Injectable({
  providedIn: 'root'
})
export class HeaderService extends RestService {

  constructor( private loadingService: LoadingService, http: HttpClient, localization: LocalizationService ) {
    super(http, localization);
    this.getMenuLinks('main');
  }

  /**
   * Extract response data
   * @param res
   * @private
   */
  private static extractParentData(res: any): Array<Link> {
    return res.links.length > 0 ? res.links : [];
  }

  /**
   * gel menu Link by token
   */
  getMenuLinks(token: string): Observable<any> {
    return this.http.get<Link>(HeaderService.apiHost + 'menu/' + token).pipe(
      map(HeaderService.extractParentData),
      catchError((err) => {
        this.loadingService.stopLoading()
        return HeaderService.handleError(err);
      })
    );
  }

  /**
   * gel amc settings
   */
  getSettings(): Observable<any> {
    return this.http.get<Settings>(HeaderService.apiHost + 'settings').pipe(
      map((res) => { return res }),
      catchError((err) => {
        this.loadingService.stopLoading()
        return HeaderService.handleError(err);
      })
    );
  }
}
