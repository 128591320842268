<Section class="field_last_SF">
  <div class="row h-100">
    <div class="col-lg-2 col-12 h-100">
      <h3 class="title"> {{ feedsConfig?.bloc?.title }} </h3>
    </div>

    <ngx-slick-carousel class="col-lg-8 col-12 flex_direction_row h-100" #slickCarousel="slick-carousel"
      [config]="slideConfig">
      <div class="d-flex" ngxSlickItem *ngFor="let news of newsroomList?.news" (click)="goToDetails(news)">
        <div class="d-flex justify-content-center align-items-center col-lg-6 col-md-6 col-xs-12">
          <div class="image_LSF">
            <img src="{{ news.image.url }}" class="h-100" alt="{{ news.title }}">
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12 h-100">
          <div class="text-LSM">
            <div class="link_linkdien" *ngIf="news.type == 'socialfeed'">
              <i class="fa fa-linkedin-square"></i>
            </div>
            <p class="mt-3">
              {{ news.summary | stripHtml }}
            </p>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>

    <div class="col-lg-2 col-md-12 col-12 btn_field">
      <div class="content_btn">
        <a class="amc-btn" (click)="goToList()" href="#">
          {{ feedsConfig?.bloc?.button_title }}
          <img src="/assets/img/icon/arrow_cta.png" class="white mt-2" alt="Arrow CTA">
          <img src="/assets/img/icon/arrow_red.png" class="orange mt-2" alt="Arrow CTA">
        </a>
      </div>
    </div>
  </div>
</Section>