import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate {
  private validLanguages: string[] = ['fr', 'en', 'de', 'es', 'pl'];

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const langCode = route.params['lang'];

    if (this.validLanguages.includes(langCode)) {
      return true;
    } else {
      this.router.navigate(['/en']);  //  Go to default (home) route
      return false;
    }
  }
}
